.iconWarning {
    color: var(--color-warning);
}

.iconError {
    color: var(--color-error)
}

.iconQuestion, .iconInfo {
    color: var(--color-info);
}