.residueCard .cardBody span {
    color: var(--text-color-title);
}

.residueCard .cardBody b {
    color: var(--text-color-default);
}

.residue-loader {
    margin: auto!important;
}