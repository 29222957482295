.carPage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.carPage .carEditForm {
    margin-top: 1em;
    display: flex;
    width: 100%;
}

.carPage .carEditForm button {
    margin-left: auto;
    margin-top: 0;
}

.carPage .carEditForm .ui.input {
    width: 50%;
}