.filter.row {
    display: flex;
    flex-direction: column;
}

.filter.row span.title {
    color: var(--text-color-title);
}

.filter.row:not(:last-child) {
    margin-bottom: .5em;
}

.filter.row .field {
    margin-left: 1em!important;
    width: auto;
}