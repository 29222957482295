.appBody {
  background-color: var(--bg-color-body);
  overflow-y: auto;
  overflow-x: hidden;  
  position: fixed;
  display: block;
  top: var(--header-height);
  width: 100%;
  bottom: 0;
  transition: var(--animation-default);
}

.appBody::before {
  height: 3.4rem;
}

.ui.input {
  height: 3rem;
  margin-bottom: .5rem;
  border: none;
  border-radius: 1rem;
  overflow: hidden;
  width: -webkit-fill-available;
  min-width: 5rem ;
  outline: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-shadow: var(--shadow);
} 

.ui.input input[type=text], 
.ui.input input[type=password] {
  background-color: var(--input-bg-color);
}

.ui.input input[type=text]:focus, 
.ui.input input[type=password]:focus {
  background-color: var(--input-bg-color-focus);
}

.ui.input.error input[type=text], 
.ui.input.error input[type=password] {
  background-color: var(--input-bg-color-error);
}

.ui.button {
  padding: var(--button-padding);
  border: none;
  border-radius: var(--border-radius-default);
  overflow: hidden;
  margin-top: 1em;
  color: var(--text-color-title);
  background-color: var(--button-bg-color-default);
  box-shadow: var(--shadow)!important;
  line-height: normal; 
} 

.ui.button:hover {
  color: var(--text-color-hover);
  background-color: var(--button-bg-color-hover);
}

.ui.green.button {
  color: var(--text-color-title);
  background-color: var(--button-bg-color-green);
  box-shadow: var(--shadow);
}

.ui.green.button:hover {
  color: var(--text-color-hover);
  background-color: var(--button-bg-color-green-hover);
}

.ui.red.button {
  color: var(--text-color-title);
  background-color: var(--button-bg-color-red);
  box-shadow: var(--shadow);
}

.ui.red.button:hover {
  color: var(--text-color-hover);
  background-color: var(--button-bg-color-red-hover);
}

.ui.icon.button:not(.labeled) {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
  border-radius: 0;
  box-shadow: none!important;
  padding: 0;
  margin: 0;
  font-size: 1em;
  font-weight: normal;
  margin-right: 0.3rem;
  color: var(--text-color-default);
}

.ui.icon.button:not(.labeled) i.icon {
  height: max-content;
  margin-bottom: .1em !important;
  font-size: 2em;
}

.ui.icon.button:hover  {
  color: var(--text-color-hover);
}

.ui.buttons::after {
  content: "";
}

.ui.buttons, .ui.buttons:not(.basic):not(.inverted) {
  border-radius: var(--border-radius-default);
  box-shadow: var(--shadow);
  font-size: 1em;
}

.ui.buttons .button {
  flex: 0 1 auto;
  box-shadow: none!important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.ui.buttons .button:first-child {
  border-radius: var(--border-radius-default) 0 0 var(--border-radius-default);
}

.ui.buttons .button:last-child {
  border-radius: 0 var(--border-radius-default) var(--border-radius-default) 0;
}

.ui.yellow.button {
  background-color: var(--button-bg-color-yellow);
  color: var(--text-color-light);
}

.ui.yellow.button:hover {
  color: var(--text-color-light-hover);
}

.ui.selection.dropdown {
  margin-left: auto;
  border-style: solid; 
  border: none;
  border-radius: var(--border-radius-default);
}

.ui.selection.active.dropdown .menu {
  min-width: 100%;
  width: 100%;
  margin: 0;
  border-radius: 0 0 var(--border-radius-default) var(--border-radius-default);
}

table.ui.celled.striped.table {
  border-radius: var(--border-radius-default);
  background-color: var(--sidebar-bg-color);
  color: var(--text-color-hover);
  overflow: hidden;
  border-color: var(--text-color-default);
}

table.ui.celled.collapsing.very.basic.table {
  color: var(--text-color-hover);
  overflow: hidden;
  border-color: var(--text-color-default);
}

table.ui.celled.table thead th {
  color: var(--text-color-title);
  background-color: var(--card-bg-color);

  border-color: var(--text-color-default);
}

table.ui.celled.table tbody tr td {
  border-color: var(--text-color-default);
}

td .actionColumn {
  display: flex;
}

td .actionColumn .action {
  margin: auto;
}

td .actionColumn button:not(:last-child), td .actionColumn .action:not(:last-child)  {
  margin-right: 1em!important;
}

table.ui.celled.table tbody tr.red td {
  background-color: var(--button-bg-color-red);
}

.infoCard .cardBody span {
  color: var(--text-color-title);
}

.infoCard .cardBody b {
  color: var(--text-color-default);
}

.page-loader {
  margin: auto!important;
}

.ui .checkbox label,
.ui.checkbox input:active~label,
.ui.checkbox input:focus~label  {
  color: var(--text-color-default);
}

.ui .checkbox {
  align-items: center;
}