.requestForm {
    display: flex;
    flex-direction: column;
}

.requestForm .requestItem {
    display: flex;
    flex-direction: column;
}

.requestForm .requestItem:not(:last-child) {
    margin-bottom: 1em;
}

.requestForm .requestItem b {
    color: var(--text-color-red)
}