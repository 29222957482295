.checkbox.picker {
    display: flex;
    flex-direction: column;
}

.checkbox.picker .ui.checkbox label, .ui.checkbox+label {
    color: var(--text-color-default)
}

.checkbox.item {
    margin-top: .5em;
}

.ui.checkbox input:checked~.box:before, .ui.checkbox input:checked~label:before,
.ui.checkbox input:focus~.box:before, .ui.checkbox input:focus~label:before,
.ui.checkbox .box:before, .ui.checkbox label:before,
.ui.checkbox .box:active::before, .ui.checkbox label:active::before,
.ui.checkbox input:checked:focus~.box:before, 
.ui.checkbox input:checked:focus~label:before {
    border-width: 1px;
    border-color: var(--text-color-default);
    background: transparent;
    color: transparent;
}

.ui.checkbox .box:hover::before, .ui.checkbox label:hover::before,
.ui.checkbox.checked .box:hover::before, .ui.checkbox.checked label:hover::before {
    background: var(--button-bg-color-hover)!important;
    border-color: var(--text-color-title)!important;
}

.ui.checkbox .box:hover::before, .ui.checkbox label:hover,
.ui.checkbox.checked .box:hover::before, .ui.checkbox.checked label:hover {
    color: var(--text-color-title)!important;
}

.ui.checkbox input:checked~.box:after, 
.ui.checkbox input:checked~label:after,
.ui.checkbox input:checked:focus~label:after {
    color: var(--text-color-title);
}