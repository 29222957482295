.card {
    display: flex;
    align-items: center;
    border-radius: var(--border-radius-default);
    padding: 1em;
    margin-top: 1em;
    background-color: var(--card-bg-color);
    box-shadow: var(--shadow)
}

.card .placeholder {
    width: 100%;
    background-color: var(--text-color-light)!important;
    background-image: 
        linear-gradient(to right,
        var(--text-color-default) 0,
        var(--text-color-hover) 15%,
        var(--text-color-default) 30%)!important;
}

.card .placeholder .image.header::after, 
.card .placeholder .line::after,
.card .placeholder .line::before,
.card .placeholder .line {
    background-color: var(--card-bg-color)!important;
}

.card .cardIcon {
    height: max-content;
}

.card .cardIcon i.icon {
    font-size: 3em;
    line-height: normal;
}

.card .cardBody {
    display: flex;
    flex-direction: column;
    margin-left: 1em;
}

.card .cardActions {
    display: flex;
    margin-left: auto;
}