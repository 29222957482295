.departureCard .cardBody span {
    color: var(--text-color-title);
}

.departureCard .cardBody b {
    color: var(--text-color-default);
}

.topPanel {
    display: flex;
    flex-wrap: wrap;
}

.topPanel button.ui.icon.left.labeled.button {
    margin: 0 0 0 auto;
    font-weight: normal;
}

.topPanel .ui.selection.dropdown {
    background-color: var(--button-bg-color-default);
    color: var(--text-color-title)!important;
    box-shadow: var(--shadow)!important;
    margin-left: 1em;
}

.topPanel .ui.selection.visible.dropdown .divider.text {
    color: var(--text-color-title)!important;
}

.topPanel .ui.active.visible.selection.dropdown .visible.menu.transition {
    background-color: var(--button-bg-color-default);
    color: var(--text-color-title)!important;
    box-shadow: var(--shadow)!important;
    border: none;
}

.topPanel .ui.active.visible.selection.dropdown .visible.menu.transition .item {
    border: none;
    color: var(--text-color-title);
}

.topPanel .ui.selection.dropdown i.icon {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    background-color: rgba(0,0,0,.05);
    width: 2.57142857em;
}

.topPanel .ui.selection.dropdown i.icon::before {
    margin: auto;
}

