.react-date-picker__wrapper {
    border: none;
    border-radius: var(--border-radius-default);
    background-color: var(--button-bg-color-default);
    padding: 0.5em;
    box-shadow: var(--shadow);
    margin-top: 0.5em;
}

input.react-date-picker__inputGroup__input,
button.react-date-picker__button svg.react-date-picker__button__icon {
    color: var(--text-color-title);
    stroke: var(--text-color-default);
    transition: var(--animation-default);
}

button.react-date-picker__button:hover svg.react-date-picker__button__icon,
button.react-date-picker__button:focus svg.react-date-picker__button__icon {
    stroke: var(--text-color-title)!important;
}

button {
    color: var(--text-color-title);
}

.react-date-picker__calendar .react-calendar,
.react-calendar__tile--now {
    background-color: var(--button-bg-color-default);
    border-radius: 1em;
    overflow: hidden;
}

.react-calendar button {
    transition: var(--animation-default);
    border-radius: 0;
}

.react-calendar__tile:disabled {
    background-color: var(--sidebar-bg-color);
}

.react-calendar__tile--active {
    background-color: var(--button-bg-color-hover);
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
    background-color: var(--button-bg-color-hover);
}

.react-calendar__navigation button[disabled] {
    background-color: var(--sidebar-bg-color);
}

.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
    background-color: var(--button-bg-color-hover);
}

.react-calendar__month-view__days__day--weekend {
    color: var(--text-color-red);
}

.date.range {
    display: flex;
    flex-wrap: wrap;
}

.date.range span.label {
    margin: 0 1em 0 0;
    color: var(--text-color-default);
}

.date.range.item {
    display: flex;
    align-items: center;
    width: max-content;
    margin: 0;
}

.date.range.item:not(:last-child) {
    margin-right: 1em;
}