@media screen and (max-width: 768px) {
    .ui.auth-form .container {
        flex-direction: column;
        width: max-content;
        max-width: calc(100% - 1em);
    }

}

.logoImage {
    display: block;
    height: 156px;
    width: 156px;
    background-image: var(--logo-login-image);
    transition: var(--animation-default);
    margin-right: 1em;
}

.ui.auth-form {
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    display: flex;
    flex-direction: column;
    position: fixed;
}

.ui.auth-form .container {
    display: flex;
    background-color: var(--bg-color-default);
    border-radius: var(--border-radius-default);
    transition: var(--animation-default);
    padding: 1em;
    box-shadow: var(--shadow);
    align-items: center;
}

.ui.auth-form .login-inputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ui.ui.auth-form .button {
    width: -webkit-fill-available;
}
.ui.auth-form span.botLink {
    margin-top: 1em;
}

.ui.auth-form span.botLink a {
    transition: var(--animation-default);
    color: var(--text-color-default);
}

.ui.auth-form span.botLink a:hover {
    color: var(--text-color-title);
}

.animated {
    animation-duration: 0.4s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
}

@keyframes shake {
    0%, 100% {transform: translateX(0);}
    40%, 80% {transform: translateX(-10px);}
    20%, 60% {transform: translateX(10px);}
}

.shake {
    animation-name: shake;
}