.settingsContainer {
    display: flex;
    flex-direction: column;
}
.settingsContainer :last-child {
    margin-bottom: 0!important;
}

.settingsContainer .setting {
    margin-bottom: 1em;
    display: flex;
    align-items: center;
}

.settingsContainer .setting :last-child {
    margin-left: auto;
}