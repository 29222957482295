.departureInfo {
    display: flex;
    flex-direction: column;
}

.departureInfo span {
    color: var(--text-color-title);
}

.departureInfo span b {
    color: var(--text-color-default);
}

button.ui.icon.button.infoButton {
    margin: auto;
}

.departureInfo .title.labAnalysisContent {
    padding-left: 1em!important;
    border-top: none!important;
}

.departureInfo td.departureHeader:last-child {
    padding: 1em 0 0 0!important;
    color: var(--text-color-default);
}

.departureInfo td.departureHeader {
    border: none!important;
}

.departureInfo td.departureTitle {
    border-top: none!important;
}

.departureInfo td.departureValue {
    border-top: none!important;
}