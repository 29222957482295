.lightTheme {
    --bg-color-body: rgb(241, 241, 241);
    --sidebar-bg-color: #e9e9e9;
    --sidebar-bg-color-hover: #e2e2e2;
    --card-bg-color: #c7c7c7;
    --bg-color-default: #d3d3d3;
    --button-bg-color-default: #c4c4c4;
    --button-bg-color-hover: #b8b8b8;
    --button-bg-color-green: #b9ccba;
    --button-bg-color-green-hover: #9cac9d;
    --button-bg-color-red: #ceb8b8;
    --button-bg-color-red-hover: #beaaaa;
    --button-bg-color-yellow: #fadc3e;
    --input-bg-color: white;
    --input-bg-color-focus: white;
    --input-bg-color-error: #fff1f1;
    --text-color-default: #757575;
    --text-color-hover: black;
    --text-color-title: black;
    --text-color-red: #aa4d50;
    --text-color-light: #525252;
    --text-color-light-hover: rgb(29, 29, 29);
    --color-warning: #be7200;
    --color-error: #aa4d50;
    --color-info: black;
    --logo-login-image: url('../img/logo_login_light.png');
  }
  
  .darkTheme {
    --bg-color-body: #2a3a3d;
    --sidebar-bg-color: #3c4b4f;
    --sidebar-bg-color-hover: #455458;
    --card-bg-color: #5b6466;
    --bg-color-default: #4e585a;
    --button-bg-color-default: #5b6466;
    --button-bg-color-hover: #939498;
    --button-bg-color-green: #536b54;
    --button-bg-color-green-hover: #6f866f;
    --button-bg-color-red: #6b5253;
    --button-bg-color-red-hover: #7a6667;
    --button-bg-color-yellow: #fadc3e;
    --input-bg-color: white;
    --input-bg-color-focus: white;
    --input-bg-color-error: #fff1f1;
    --text-color-default: #939498;
    --text-color-hover: white;
    --text-color-title: white;
    --text-color-red: #efa0a2;
    --text-color-light: #2a3a3d;
    --text-color-light-hover: rgb(31, 26, 26);
    --color-warning: #c4c48f;
    --color-error: #efa0a2;
    --color-info: #white;
    --logo-login-image: url('../img/logo_login_dark.png');
  }
  