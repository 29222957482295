header {
    position: fixed;
    top: 0;
    height: var(--header-height);
    width: 100%;
    display: flex!important;
    background-color: var(--bg-color-default);
    padding: 0.2rem;
    color: var(---text-color-default);
    z-index: 250;
    transition: var(--animation-default);
    box-shadow: var(--shadow)
}

header .leftBlock {
    height: 100%;
    margin-left: 0.2em;
    display: flex;
    align-items: center;
}

header .leftBlock .image {
    height: 100%;
}

header .userBlock {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
}

header .userBlock .userName {
    display: inline-block;
    color: var(--text-color-title);
    font-size: 1.5em;
}

header .userBlock span {
    color: var(--text-color-default);
    text-align: center;
}

header .rightBlock {
    height: 100%;
    display: flex;
    align-items: center;
}

header .rightBlock .button, header .leftBlock .button {
    padding: .5em 1em;
    box-shadow: none!important;
}