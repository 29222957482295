.mainPage {
    display: flex;
}

@media screen and (max-width: 768px) {
    .mainPage .sidebar {
        display: block;
        position: fixed;
        width: 100vw;
        top: var(--header-height);
        bottom: 0;
        background-color: transparent;
        z-index: 10;
        transition: var(--animation-default);
    }

    .mainPage .sidebar .tabs {
        display: flex;
        flex-direction: column;
        position: relative;
        top: 0;
        background-color: var(--sidebar-bg-color);
        box-shadow: var(--shadow);
        height: 100%;
        width: 50%;
        overflow: hidden;
        padding: 3em 0;
        transform: translate(0, 0);
        transition: var(--animation-default);
    }

    .mainPage .sidebar.hide {
        transform: translate(calc(-100vw - 10px), 0);
    }

    .mainPage .tabs a {
        color: var(--text-color-default);
        margin: 0;
        padding: 1rem;
        background: var(--sidebar-bg-color-hover);
        border: 1px solid var(--sidebar-bg-color);
        outline: none !important;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        transition: var(--animation-default);
    }

    .mainPage .tabs a.active {
        cursor: default;
        color: var(--text-color-title);
        background-color: var(--bg-color-default);
        padding-left: 1em;
        font-weight: bold;
    }

    .mainPage .content {
        display: flex;
        flex-direction: column;
        background-color: var(--bg-color-default);
        color: var(--text-color-title);
        border-radius: var(--border-radius-default);
        padding: 1rem;
        margin: 2rem 0 6rem 0;
        z-index: 1;
        box-shadow: var(--shadow);
        width: -webkit-fill-available;
        width: -moz-available;
        transition: var(--animation-default);
    }

    .ui.icon.button.toggleMenu {
        flex-direction: row!important;
        position: fixed;
        bottom: 1em;
        left: 1em;
        z-index: 30;
        border-radius: var(--border-radius-default)!important;
        background-color: var(--button-bg-color-default)!important;
        box-shadow: var(--shadow)!important;
        padding: .8em!important;
    }

    .ui.icon.button.toggleMenu i.icon {
        margin-bottom: 0!important;
        margin-right: .1em!important;
        transition: transform .1s ease-in-out!important;
    }

    .ui.icon.button.toggleMenu:not(.hide) i.icon {
        transform: rotateZ(90deg)!important;
    }
}

@media screen and (min-width: 769px) {
    .mainPage .sidebar {
        display: block;
        width: 500px;
        overflow: hidden;
    }

    .mainPage .tabs {
        display: flex;
        flex-direction: column;
        background: var(--sidebar-bg-color);
        border-radius: var(--border-radius-default) 0 0 var(--border-radius-default);
        margin: 3rem 0 3rem 2rem;
        height: max-content;
        box-shadow: var(--shadow);
        transition: var(--animation-default);
    }

    .mainPage .tabs a {
        color: var(--text-color-default);
        margin: 0;
        padding: 1rem;
        background: transparent;
        border: none;
        outline: none !important;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        transition: var(--animation-default);
        margin-right: -2rem;
        padding-right: 2rem;
    }

    .mainPage .tabs a:first-child {
        border-top-left-radius: var(--border-radius-default);
    }

    .mainPage .tabs a:last-child {
        border-bottom-left-radius: var(--border-radius-default);
    }

    .mainPage .tabs a:hover {
        cursor: pointer;
        background-color: var(--sidebar-bg-color-hover);
    }

    .mainPage .tabs a.active {
        cursor: default;
        border-radius: var(--border-radius-default) 0 0 var(--border-radius-default);
        color: var(--text-color-title);
        transform: translate(-1rem, 0) scaleY(1.1);
        background-color: var(--bg-color-default);
        box-shadow: var(--shadow);
        padding-right: 3rem;
        font-weight: bold;
        z-index: 2;
    }

    .mainPage .tabs a.active :hover {
        background-color: var(--bg-color-default);
    }

    .mainPage .content {
        display: flex;
        flex-direction: column;
        background-color: var(--bg-color-default);
        color: var(--text-color-title);
        border-radius: var(--border-radius-default);
        padding: 1rem;
        margin: 2rem 1rem 1rem 0;
        z-index: 1;
        box-shadow: var(--shadow);
        width: -webkit-fill-available;
        width: -moz-available;
        transition: var(--animation-default);
    }

    .toggleMenu {
        display: none!important;
    }
}

.mainPage .content h1 {
    margin: 0 0 .5em 0;
    font-size: 1.5em;
}

.mainPage .content hr {
    margin: 0 0 1em 0;
}