@media screen and (max-width: 768px) {
    .users .userCard .userIcon {
        display: none!important;
    }

    .users .userCard .userInfo {
        margin-left: 0em!important;
    }
}

.users {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.users .userName {
    font-weight: bold;
    margin-bottom: .5em;
}

.users .userLogin {
    color: var(--text-color-default);
}

.users .userRole {
    color: var(--text-color-default);
}

.users .userBottom {
    display: flex;
    flex-direction: column;
    margin-top: auto;
}

.users .userBottom .addUser {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    color: #939498;
    border: none;
    background-color: #56615f;
    box-shadow: 0 0 10px rgba(0, 0, 0, .25);
    border-radius: 1em;
    padding: 1em;
    outline: none !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: all 0.1s ease-in-out;
}

.users .userBottom .addUser:hover {
    cursor: pointer;
    color: white;
    background-color: #939498;
}

.users .userBottom .addUser:active {
    box-shadow: 0 0 5px rgba(0, 0, 0, .25);
    background-color: #707175;
}

.users .userBottom .addUser svg {
    width: 2em;
    height: 2em;
}

.users .userBottom .addUser span {
    margin-left: 1em;
    font-size: 1.5em;
}