@media screen and (max-width: 768px) {
    .ui.icon.button.deleteButton span {
        display: none;
    }
}


.ui.icon.button.deleteButton {
    color: var(--text-color-red)!important;
    margin-left: 1em;
}

.ui.icon.button.deleteButton:hover {
    color: var(--text-color-hover)!important;
}
