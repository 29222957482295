:root {
  --button-padding: 1em;
  --button-font-size: 1em;
  --shadow: 0 0 10px rgba(0, 0, 0, .25);
  --border-radius-default: 1em;
  --header-height: 3em;
  --animation-default: all 0.1s ease-in-out;
  --transform-animation-default: transform 0.2s ease-in-out;
}

body {
  background-color: #2a3a3d;
  color: #cccccc;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /*-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}