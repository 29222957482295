.ui.modal {
    display: flex;
    align-items: center;
    min-width: 20rem;
    width: max-content;
    padding: 1em;
    border-radius: var(--border-radius-default);
    box-shadow: var(--shadow);
    background-color: var(--bg-color-default);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    max-width: 95vw;
}

.ui.modal .header:not(.ui) {
    font-size: 1em;
    font-weight: bold;
    padding-bottom: .5em;
}

.ui.modal .header {
    display: flex;
    align-items: center;
    background-color: transparent;
    color: var(--text-color-title);
    font-weight: bold;
    padding: 0 0 1em 0 !important;
    margin-bottom: 1em;
    border-bottom: 1px solid var(--text-color-default);
}

.ui.modal .header i.icon {
    height: max-content;
}

.ui.modal .content {
    background-color: transparent;
    padding: 0 !important;
    font-size: 1em;
    color: var(--text-color-default);
}

.ui.modal .actions {
    background-color: transparent;
    margin-top: 1em;
    padding: 0 !important;
    font-size: 1em;
    border-top: 1px solid var(--text-color-default);
}