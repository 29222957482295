@media screen and (max-width: 768px) {
    .ui.icon.button.editButton span {
        display: none;
    }
}

.ui.icon.button.editButton i.icon {
    margin-left: .2em!important;
}

.userForm {
    display: grid;
}

.userForm .userFormRow {
    display: flex;
    align-items: center;
    margin-top: 1em
}

.userForm .userFormRow .phones {
    width: 10rem;
    display: flex;
    flex-direction: column;
    margin-left: auto;
}

.userForm .userFormRow .editField {
    width: 10rem;
    margin-left: auto;
    border-style: solid;
    border: none;
}

.userForm .userFormRow .phoneField {
    display: flex;
    align-items: center;
}

.userForm .userFormRow .phoneField .deletePhone {
    color: var(--text-color-red)!important;
    position: absolute;
    right: 0;
}

.selectField {
    min-width: 10rem!important;
    width: 10rem;
}