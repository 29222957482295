.notify {
    display: flex;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.4s ease-out;
    max-height: 200px;
    z-index: 9999;
}

.notify.deleting {
    max-height: 0;
    transition: (0, -100%);
}

.notifyBody {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 1em;
    margin-top: 1em;
    flex-direction: column;
    color: var(--text-color-default);
    background-color: var(--bg-color-default);
    border-radius: var(--border-radius-default);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.4s ease-in-out;
    pointer-events: all;
    opacity: 1;
    box-shadow: var(--shadow)
}

.notifyBody.deleting {
    bottom: 0;
    left: 0;
    opacity: 0;
    margin-top: 0;
    padding: 0;    
    transform: translate(-1rem, -1rem);
}

.notify .notifyTitle {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: var(--text-color-title);
    margin-bottom: 1em;
}

.notify .notifyClose {
    cursor: pointer;
    position: absolute;
    right: 1em;
}

.notify .notifyTitle i.icon.close { 
    margin: -1em -.5em 0 auto;
    color: var(--text-color-red);
    transition: var(--transform-animation-default);
    padding: 0;
    height: fit-content;
}

.notify .notifyTitle i.icon.close:hover { 
    cursor: pointer;
    transform: scale(1.2);
}