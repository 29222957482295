.notifyBlock {
    display: flex;
    position: absolute;
    pointer-events: none;
}

@media screen and (max-width: 768px) {
    .notifyBlock {
        flex-direction: column-reverse;
        left: 50%;
        width: 90%;
        top: calc(var(--header-height) + 1em);
        transform: translate(-50%, 0);
        z-index: 9999;
    }
}

@media screen and (min-width: 769px) {
    .notifyBlock {
        overflow: hidden;
        flex-direction: column;
        left: 0;
        bottom: 0;
        width: 30%;
        padding: 1rem;
    }
}